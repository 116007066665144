'use client';

import type { FC, HTMLProps } from 'react';

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css' assert { type: 'css' };
import 'slick-carousel/slick/slick-theme.css' assert { type: 'css' };
import Overview1 from '@/assets/overview/1.png' assert { type: 'png' };
import Overview10 from '@/assets/overview/10.png' assert { type: 'png' };
import Overview11 from '@/assets/overview/11.png' assert { type: 'png' };
import Overview2 from '@/assets/overview/2.png' assert { type: 'png' };
import Overview3 from '@/assets/overview/3.png' assert { type: 'png' };
import Overview4 from '@/assets/overview/4.png' assert { type: 'png' };
import Overview5 from '@/assets/overview/5.png' assert { type: 'png' };
import Overview6 from '@/assets/overview/6.png' assert { type: 'png' };
import Overview7 from '@/assets/overview/7.png' assert { type: 'png' };
import Overview8 from '@/assets/overview/8.png' assert { type: 'png' };
import Overview9 from '@/assets/overview/9.png' assert { type: 'png' };
import { H2, Figure, Keyboard } from '@/components/primitives';

const PrevButton: FC<HTMLProps<HTMLButtonElement>> = ({ onClick }) => (
  <button
    className='absolute -left-0.5 top-1/2 z-20 cursor-pointer rounded-lg bg-blue-500 p-5 text-xl text-white hover:bg-blue-800'
    onClick={onClick}
    aria-label='이전 슬라이드'
    title='이전 슬라이드'
    type='button'
  >
    ←
  </button>
);
const NextButton: FC<HTMLProps<HTMLButtonElement>> = ({ onClick }) => (
  <button
    className='absolute -right-0.5 top-1/2 z-20 cursor-pointer rounded-lg bg-blue-500 p-5 text-xl text-white hover:bg-blue-800'
    onClick={onClick}
    aria-label='다음 슬라이드'
    title='다음 슬라이드'
    type='button'
  >
    →
  </button>
);

const PreviewSection = () => (
  <section id='preview' className='bg-white p-5 pb-16'>
    <H2>미리보기</H2>
    <Slider
      dots
      infinite
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
      prevArrow={<PrevButton />}
      nextArrow={<NextButton />}
    >
      <Figure
        src={Overview1}
        alt='룬 별로 색상이 달라서 색상과 톤으로 룬과 각 룬의 등급을 보다 편하게 파악할 수 있습니다. 같이 표기된 보석은 룬 합치기에 필요한 보석입니다.'
      />
      <Figure
        src={Overview2}
        alt='Pretendard 글꼴을 활용하여 글씨의 가독성이 높고, 아이템 옵션들에 유형별로 구분된 강조를 넣어 한눈에 파악하기 용이합니다.'
      />
      <Figure
        src={Overview3}
        alt='암살자(어쌔신)의 무술 스택이 얼마나 쌓여있는지 한눈에 볼 수 있습니다. 모자씬 육성에 특히 유용합니다.'
      />
      <Figure
        src={Overview4}
        alt='현재 어느 버프를 받은 상태인지 각 캐릭터별로 볼 수 있습니다. 또한 불길의 강에서 용암을 삭제하여 버벅임 현상을 완화하였습니다.'
      />
      <Figure
        src={Overview5}
        alt='길찾기 스페셜로 웨이포인트를 찾기 쉽습니다. 또한 맵 전역 광원을 조정하여 던전 내부가 어둡지 않습니다.'
      />
      <Figure
        src={Overview6}
        alt='블리자드에서 공식 제공하는 안전한 방식으로 개발된 길찾기 스페셜로 중요 던전이나 퀘스트 아이템, 보스를 빠르게 찾아보세요.'
      />
      <Figure
        src={Overview7}
        alt='G를 눌러 레거시 모드에 들어가면 나오는 상세한 아이템 옵션 및 추가 설명'
        caption={
          <>
            <Keyboard>G</Keyboard>를 눌러 레거시 모드에 들어가면 나오는 상세한 아이템 옵션 및 추가 설명
          </>
        }
      />
      <Figure
        src={Overview8}
        alt='H를 눌러 도움말을 열어서 언제나 볼 수 있는 다양하고 유익한 게임 정보들'
        caption={
          <>
            <Keyboard>H</Keyboard>를 눌러 도움말을 열어서 언제나 수 있는 다양하고 유익한 게임 정보들
          </>
        }
      />
      <Figure src={Overview9} alt='룬 순서와 변동옵션까지 확인 가능한 룬워드 백과사전' />
      <Figure
        src={Overview10}
        alt='인벤토리 우측에서 빠르게 볼 수 있는 아이템별 주요 접두/접미사 정보 (내용 검수: FBI멀더)'
      />
      <Figure src={Overview11} alt='디아블로 2 영문판에서도 스킨 사용 가능!' />
    </Slider>
  </section>
);
export default PreviewSection;
