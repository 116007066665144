import(/* webpackMode: "eager" */ "/home/item4/proj/more.item4.net/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/item4/proj/more.item4.net/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/item4/proj/more.item4.net/src/assets/install-guide/1.png");
;
import(/* webpackMode: "eager" */ "/home/item4/proj/more.item4.net/src/assets/install-guide/2.png");
;
import(/* webpackMode: "eager" */ "/home/item4/proj/more.item4.net/src/assets/install-guide/3.png");
;
import(/* webpackMode: "eager" */ "/home/item4/proj/more.item4.net/src/assets/install-guide/launcher.png");
;
import(/* webpackMode: "eager" */ "/home/item4/proj/more.item4.net/src/components/nav/NavLink/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/item4/proj/more.item4.net/src/components/pages/DownloadButton/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/item4/proj/more.item4.net/src/components/pages/PreviewSection/index.tsx");
