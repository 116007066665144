'use client';
import type { AnchorHTMLAttributes, FC } from 'react';

import Link from 'next/link';
import { useCallback } from 'react';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  targetId: string;
}

const NavLink: FC<Props> = ({ children, targetId, ...props }) => {
  const handleClick = useCallback(() => {
    document.getElementById(targetId)?.scrollIntoView({ behavior: 'smooth' });
  }, [targetId]);
  return (
    <Link
      {...props}
      href={`#${targetId}`}
      className='text-md text-blue-600 hover:text-blue-900 hover:underline'
      scroll={false}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};
export default NavLink;
